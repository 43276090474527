import React from 'react';
import { SectionContainer, SectionTitle } from '../styles/Section.styles';

const Section = () => {
    return (
        <SectionContainer>
            <SectionTitle>Coming soon..</SectionTitle>
        </SectionContainer>
    );
};

export default Section;
