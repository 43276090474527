import React from 'react';
import { InformationContainer, InformationContent, CloseButton } from '../styles/InformationSectionWithHeader.styles';

const InformationSectionWithHeader = ({ onClose }) => {
  return (
    <InformationContainer onClick={onClose}>
      <InformationContent onClick={(e) => e.stopPropagation()}>
        <h2>How to become the coolest POOMB Creatures "CO-OWNER?"</h2>
        <p>
          Subscribe and invite your friends to the game right now!
          <br />
          You can receive <div className='gold'>150.000 $COGA tokens</div>numerous bonuses in the form of exciting characters, unique add-ons, game bonuses, a lot of coins and real tokens that other users won't have!!
          <br />
          This offer is only available for users who start playing our game right now!.
        </p>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </InformationContent>
    </InformationContainer>
  );
};

export default InformationSectionWithHeader;
