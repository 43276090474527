import styled from 'styled-components';

export const SocialLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  position: fixed;
  bottom: ${props => props.bottom || '35px'};
  width: 100%;
  z-index: 3;
`;

export const SocialLink = styled.a`
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover img {
    opacity: 0.8;
  }
`;
