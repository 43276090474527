import React, { useEffect, useState } from 'react';
import {
  CheckContainer,
  Button,
  InfoButton,
  TextContainer,
} from '../styles/SubscriptionCheck.styles';
import InformationSectionWithHeader from './InformationSectionWithHeader';

const SubscriptionCheck = ({ userId }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showInfoSection, setShowInfoSection] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check_subscription/${userId}`, {
          headers: { 'Cache-Control': 'no-cache' }
        });
        const data = await response.json();
        setIsSubscribed(data.is_subscribed);
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    };

    const intervalId = setInterval(checkSubscription, 1000);

    return () => clearInterval(intervalId);
  }, [userId]);

  const handleSubscribe = () => {
    window.open('https://t.me/capy_games', '_blank');
  };

  const handleOpenInfoSection = () => {
    setShowInfoSection(true);
  };

  const handleCloseInfoSection = () => {
    setShowInfoSection(false);
  };

  const handlePlayClick = () => {
    window.location.reload(); // Обновляем страницу
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CheckContainer>
      {showInfoSection && (
        <InformationSectionWithHeader onClose={handleCloseInfoSection} />
      )}
      <TextContainer>
        <h2>Choose your Poomby Pet and start leveling its Hub</h2>
        <p>Ask your friends to join the game to get more levels open!</p>
        <p className="gold">GET 150.000 $COGA TOKENS!!</p>
        <p>
          <b>More friends - more bonuses, earnings, opportunities and real money earnings</b> after the main game launches!
        </p>
        <p>
          *5 Poomby Pets for choosing are <b>unavailable</b> since you are not subscribed to our main Poomb Creatures Game Channel
        </p>
        <p>Please, do that right now! Let the journey begin!</p>
      </TextContainer>
      <Button disabled={isSubscribed} onClick={handleSubscribe} active={!isSubscribed}>
        Subscribe
      </Button>
      <Button disabled={!isSubscribed} onClick={handlePlayClick} active={isSubscribed}>
        Play
      </Button>
      <InfoButton onClick={handleOpenInfoSection} />
    </CheckContainer>
  );
};

export default SubscriptionCheck;
