import React, { useState, useEffect } from 'react';
import { LoaderContainer, Spinner, LoadingText } from '../styles/Loader.styles';
import SocialLinks from './SocialLinks';
import MarvinRegular from '../assets/fonts/Marvin-Regular.otf'; // Импортируем шрифт

const Loader = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const minimumLoadingTime = 2500;

    useEffect(() => {
        const resources = [
            '../assets/images/mainBG.png',
            '../assets/images/clickable_image.png',
            '../assets/images/Splashscreen.png',
            MarvinRegular, // Добавляем шрифт в список ресурсов
        ];

        const loadResource = (src) => {
            return new Promise((resolve, reject) => {
                if (src.endsWith('.otf')) {
                    const font = new FontFace('Marvin Regular', `url(${src})`);
                    font.load().then(() => {
                        document.fonts.add(font);
                        resolve();
                    }).catch(reject);
                } else {
                    const img = new Image();
                    img.src = src;
                    img.onload = resolve;
                    img.onerror = reject;
                }
            });
        };

        const loadResources = () => {
            return Promise.all(resources.map(loadResource));
        };

        const loadContent = async () => {
            const startTime = Date.now();
            try {
                await loadResources();
            } catch (err) {
                console.error("Ошибка при загрузке ресурсов", err);
            }
            const elapsedTime = Date.now() - startTime;
            const remainingTime = minimumLoadingTime - elapsedTime;

            if (remainingTime > 0) {
                setTimeout(() => {
                    setIsLoading(false);
                }, remainingTime);
            } else {
                setIsLoading(false);
            }
        };

        loadContent();
    }, []);

    if (isLoading) {
        return (
            <LoaderContainer>
                <Spinner />
                <LoadingText>Loading</LoadingText>
                <SocialLinks bottom="100px" />
            </LoaderContainer>
        );
    }

    return children;
};

export default Loader;
