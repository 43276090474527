import styled, { keyframes } from 'styled-components';
import splashscreen from '../assets/images/Splashscreen.png';


const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(${splashscreen});
  background-size: cover;
  background-position: center;
`;

export const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #f39c12;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingText = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: white;
  font-family: 'Marvin Regular', serif;
`;
