import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  color: #333;
  text-shadow:
    -1px -1px 0 #fff,  
     1px -1px 0 #fff,
    -1px  1px 0 #fff,
     1px  1px 0 #fff;
`;
