import styled, { keyframes } from 'styled-components';

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
  height: 100%;
  max-width: 600px;
  min-height: 400px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const ClickableImage = styled.img`
  width: 230px;
  height: auto;
  max-height: 400px;
  margin-bottom: 20px;
  user-select: none;
  outline: none;
  transition: transform 0.2s ease;

  &:focus {
    outline: none;
  }

  &:active {
    animation: ${clickAnimation} 0.2s ease-in-out;
  }
`;
