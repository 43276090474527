import React from 'react';
import {
  UpgradeContainer,
  UpgradeOverlay,
  UpgradeButton,
  UpgradeText,
  CloseButton
} from '../styles/UpgradePopup.styles';

const UpgradePopup = ({ onUpgrade, onClose, currentEnergyLevel, currentBalanceLevel, userBalance }) => {
  
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <UpgradeOverlay onClick={handleOverlayClick}>
      <UpgradeContainer>
        <CloseButton onClick={onClose}>Close</CloseButton>
        <UpgradeText>Energy Upgrade Level: {currentEnergyLevel}</UpgradeText>
        <UpgradeButton
          onClick={() => onUpgrade('energy', currentEnergyLevel + 1, 100 * currentEnergyLevel)}
          disabled={userBalance < 100 * currentEnergyLevel}
        >
          Upgrade Energy
        </UpgradeButton>

        <UpgradeText>Balance Upgrade Level: {currentBalanceLevel}</UpgradeText>
        <UpgradeButton
          onClick={() => onUpgrade('balance', currentBalanceLevel + 1, 100 * currentBalanceLevel)}
          disabled={userBalance < 100 * currentBalanceLevel}
        >
          Upgrade Balance
        </UpgradeButton>
      </UpgradeContainer>
    </UpgradeOverlay>
  );
};

export default UpgradePopup;
