import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserBalance = async (userId) => {
    const response = await axios.get(`${API_URL}/user/${userId}/balance`);
    return response.data.balance;
};

export const updateUserBalance = async (userId, amount) => {
    const response = await axios.put(`${API_URL}/user/${userId}/balance`, { amount });
    return response.data.balance;
};

export const getUserInfo = async (userId) => {
    const response = await axios.get(`${API_URL}/user/${userId}`);
    return response.data;
};

export const updateUserEnergy = async (userId, amount) => {
    const response = await axios.put(`${API_URL}/user/${userId}/energy`, { amount });
    return response.data;
};

export const getUserUpgrades = async (userId) => {
    const response = await axios.get(`${API_URL}/user/${userId}/upgrades`);
    return response.data;
};

export const updateUserUpgrade = async (userId, upgradeType, newLevel) => {
    const response = await axios.put(`${API_URL}/user/${userId}/upgrade`, {
        upgradeType,
        newLevel
    });
    return response.data;
};

export const checkSubscription = async (userId) => {
    const response = await axios.get(`${API_URL}/check_subscription/${userId}`);
    return response.data;
};
