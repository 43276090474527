import { createGlobalStyle } from 'styled-components';
import MarvinRegular from '../assets/fonts/Marvin-Regular.otf';
import MarvinRound from '../assets/fonts/Marvin-Round.otf';
import MarvinShadow from '../assets/fonts/Marvin-Shadow.otf';
import mainBG from '../assets/images/mainBG.png';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Marvin Regular';
    src: url(${MarvinRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Marvin Round';
    src: url(${MarvinRound}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Marvin Shadow';
    src: url(${MarvinShadow}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: 'Marvin Regular', Arial, sans-serif;
    background-color: #10582B;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${mainBG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Marvin Round', Arial, sans-serif;
  }

  .shadow-text {
    font-family: 'Marvin Shadow', Arial, sans-serif;
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
  }
`;

export default GlobalStyle;
