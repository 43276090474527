import React, { useEffect, useState } from 'react';
import { getUserInfo } from '../api';
import {
  HeaderContainer,
  UserStatus,
  Status,
  Button
} from '../styles/Header.styles';
import UpgradesPopup from './UpgradePopup';

const Header = ({ userId, onUpgrade }) => {
  const [username, setUsername] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    getUserInfo(userId)
      .then(user => setUsername(user.username))
      .catch(error => {
        console.error('There was an error fetching the user data!', error);
      });
  }, [userId]);

  const handleConfirmUpgrades = (energyUpgrade, balancePerClickUpgrade) => {
    onUpgrade(energyUpgrade, balancePerClickUpgrade); // Передаем изменения в родительский компонент
    setShowPopup(false); // Закрываем поп-ап
  };

  return (
    <HeaderContainer>
      <UserStatus>
        <Status>
          <span>{username}</span>
        </Status>
      </UserStatus>
      <Button onClick={() => setShowPopup(true)}>Upgrades</Button>
      {showPopup && <UpgradesPopup onConfirm={handleConfirmUpgrades} />}
    </HeaderContainer>
  );
};

export default Header;
