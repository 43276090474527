import React from 'react';
import { PopupContainer, PopupMessage, PopupButton } from '../styles/Popup.styles';

const AutoClickerPopup = ({ onConfirm, accumulatedBalance }) => {
  return (
    <PopupContainer>
      <PopupMessage>
        You've accumulated {accumulatedBalance} during your absence.
      </PopupMessage>
      <PopupButton onClick={onConfirm}>
        Add to balance
      </PopupButton>
    </PopupContainer>
  );
};

export default AutoClickerPopup;
