import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const MenuContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  background-color: #282c34;
  padding: 10px 0;
  width: 100%;
  max-width: 600px;
  position: fixed;
  bottom: 0;
  left: 50%; 
  transform: translateX(-50%);

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;

  &.active {
    color: #76c7c0;

    svg {
      fill: #76c7c0;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    fill: currentColor;

    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    color: #76c7c0;

    svg {
      fill: #76c7c0;
    }
  }
`;

export const MenuLabel = styled.span`
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
