import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  color: white;
  border-radius: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const UserStatus = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NotConnected = styled.span`
  color: red;
`;

export const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #61dafb;
  color: #282c34;
  cursor: pointer;

  &:hover {
    background-color: #21a1f1;
  }

  @media (max-width: 768px) {
    margin-left: auto;
  }
`;
