import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ClickableImage, Container } from '../styles/Clicker.styles';
import { updateUserBalance, updateUserEnergy, getUserInfo } from '../api';
import clickableImage from '../assets/images/clickable_image.png';
import EnergyBar from './EnergyBar';

const Clicker = ({ userId, onBalanceChange, onEnergyChange, energyUpgradeLevel, balanceUpgradeLevel }) => {
  const [currentEnergy, setCurrentEnergy] = useState(0);
  const [maxEnergy, setMaxEnergy] = useState(0);
  const [localBalance, setLocalBalance] = useState(0);

  const balanceTimeoutRef = useRef(null);
  const energyTimeoutRef = useRef(null);
  const energyIntervalRef = useRef(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await getUserInfo(userId);
        setCurrentEnergy(userInfo.current_energy);
        setMaxEnergy(userInfo.max_energy);
        setLocalBalance(userInfo.balance);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, [userId]);

  const resetTimers = useCallback(() => {
    if (balanceTimeoutRef.current) clearTimeout(balanceTimeoutRef.current);
    if (energyTimeoutRef.current) clearTimeout(energyTimeoutRef.current);
    if (energyIntervalRef.current) clearInterval(energyIntervalRef.current);

    balanceTimeoutRef.current = setTimeout(() => {
      updateUserBalance(userId, localBalance);
    }, 3000);

    energyTimeoutRef.current = setTimeout(() => {
      energyIntervalRef.current = setInterval(() => {
        setCurrentEnergy(prevEnergy => {
          if (prevEnergy < maxEnergy) {
            const newEnergy = prevEnergy + energyUpgradeLevel; // Учитываем улучшение энергии
            onEnergyChange(newEnergy);
            if (newEnergy % 50 === 0) {
              updateUserEnergy(userId, -(newEnergy - prevEnergy));
            }
            return newEnergy;
          }
          return prevEnergy;
        });
      }, 1000);
    }, 1500);
  }, [localBalance, maxEnergy, energyUpgradeLevel, onEnergyChange, userId]);

  const handleClick = useCallback(() => {
    if (currentEnergy > 0) {
      setCurrentEnergy(prevEnergy => {
        const newEnergy = prevEnergy - 1;
        onEnergyChange(newEnergy);
        return newEnergy;
      });

      setLocalBalance(prevBalance => {
        const newBalance = prevBalance + balanceUpgradeLevel; // Учитываем улучшение баланса
        onBalanceChange(newBalance);
        return newBalance;
      });

      resetTimers();
    }
  }, [currentEnergy, balanceUpgradeLevel, onBalanceChange, onEnergyChange, resetTimers]);

  useEffect(() => {
    return () => {
      if (balanceTimeoutRef.current) clearTimeout(balanceTimeoutRef.current);
      if (energyTimeoutRef.current) clearTimeout(energyTimeoutRef.current);
      if (energyIntervalRef.current) clearInterval(energyIntervalRef.current);
    };
  }, []);

  return (
    <Container>
      <ClickableImage
        src={clickableImage}
        alt="Clickable"
        onClick={handleClick}
      />
      <EnergyBar currentEnergy={currentEnergy} maxEnergy={maxEnergy} />
    </Container>
  );
};

export default Clicker;
