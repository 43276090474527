import React from 'react';
import { SocialLinksContainer, SocialLink } from '../styles/SocialLinks.styles';
import inst from '../assets/images/inst.png';
import fb from '../assets/images/fb.png';
import x from '../assets/images/x.png';
import yt from '../assets/images/yt.png';
import ds from '../assets/images/ds.png';
import tt from '../assets/images/tt.png';

const SocialLinks = ({ bottom }) => {
  return (
    <SocialLinksContainer bottom={bottom}>
      <SocialLink href="https://www.instagram.com/poomb.creatures?igsh=aDN2cHk2N2xmOXlh" target="_blank">
        <img src={inst} alt="Instagram" />
      </SocialLink>
      <SocialLink href="https://www.facebook.com/share/Ki5D47MwUq1vzbcC/?mibextid=LQQJ4d" target="_blank">
        <img src={fb} alt="Facebook" />
      </SocialLink>
      <SocialLink href="https://x.com/Poomb_Creatures" target="_blank">
        <img src={x} alt="Twitter" />
      </SocialLink>
      <SocialLink href="https://www.youtube.com/@Poomb_creatures_game" target="_blank">
        <img src={yt} alt="YouTube" />
      </SocialLink>
      <SocialLink href="https://discord.gg/xrbtCHuF" target="_blank"> 
        <img src={ds} alt="Discord" />
      </SocialLink>
      <SocialLink href="https://www.tiktok.com/@poomb.app?_t=8nqbk76iJdd&_r=1" target="_blank">
        <img src={tt} alt="TikTok" />
      </SocialLink>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
