import React, { useState, useEffect } from 'react';
import AutoClickerPopup from './AutoClickerPopup'; // Подключаем pop-up

const AutoClicker = ({ userId, onBalanceChange }) => {
  const [isAway, setIsAway] = useState(false);
  const [accumulatedBalance, setAccumulatedBalance] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsAway(true);
      } else {
        setIsAway(false);
        setShowPopup(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isAway) {
      interval = setInterval(() => {
        setAccumulatedBalance((prev) => prev + 1);
      }, 2000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isAway]);

  const handleConfirm = () => {
    onBalanceChange((prevBalance) => prevBalance + accumulatedBalance);
    setAccumulatedBalance(0);
    setShowPopup(false);
  };

  return showPopup ? (
    <AutoClickerPopup onConfirm={handleConfirm} accumulatedBalance={accumulatedBalance} />
  ) : null;
};

export default AutoClicker;
