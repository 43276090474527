import styled from 'styled-components';
import shopBackground from '../assets/images/Shop.png';
import infoButton from '../assets/images/infoButton.png'; 

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  font-family: 'Marvin Regular', serif;
  position: relative;
  background-image: url(${shopBackground});
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

export const TextContainer = styled.div`
  max-width: 600px;
  margin-bottom: 10px;
  margin-top: 25px;
  font-family: 'Marvin Regular', serif;
  color: black;
  font-size: 16px;
  h2 {
    color: #F8CE00;
    text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    font-size: 22px;
  }
  .gold {
    color: #F8CE00;
    text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    font-size: 22px;
  }
`;

export const Button = styled.button`
  background-color: ${props => (props.disabled ? '#ccc' : '#4CAF50')};
  color: white;
  padding: 20px 40px;
  font-size: 18px;
  font-family: 'Marvin Regular', serif;
  border: none;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? '#ccc' : '#45a049')};
  }
`;

export const InfoButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 35px;
  height: 35px;
  background-image: url(${infoButton});
  background-size: cover;
  background-position: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
