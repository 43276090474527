import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  z-index: 1000;
`;

export const PopupMessage = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const PopupButton = styled.button`
  background-color: #f39c12;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e67e22;
  }
`;
