import styled from 'styled-components';

export const BalanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  min-height: 50px; /* Фиксируем минимальную высоту */

  @media (max-width: 768px) {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
  }
`;

export const Balance = styled.div`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
