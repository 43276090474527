import styled from 'styled-components';

export const EnergyBarContainer = styled.div`
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  min-height: 20px;

`;

export const EnergyFill = styled.div`
  height: 30px;
  background-color: #76c7c0;
  width: ${props => (props.currentEnergy / props.maxEnergy) * 100}%;
  transition: width 0.3s ease-in-out;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

export const EnergyText = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #fff;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
