import React from 'react';
import { MenuContainer, MenuItem, MenuLabel } from '../styles/Menu.styles';
import { ReactSVG } from 'react-svg';
import earnIcon from '../assets/icons/earn.svg';
import inviteIcon from '../assets/icons/invite.svg';
import fightIcon from '../assets/icons/fight.svg';
import topIcon from '../assets/icons/top.svg';
import rewardsIcon from '../assets/icons/rewards.svg';

const Menu = () => {
    return (
        <MenuContainer>
            <MenuItem to="/earn">
                <ReactSVG src={earnIcon} />
                <MenuLabel>Earn</MenuLabel>
            </MenuItem>
            <MenuItem to="/invite">
                <ReactSVG src={inviteIcon} />
                <MenuLabel>Invite</MenuLabel>
            </MenuItem>
            <MenuItem to="/fight">
                <ReactSVG src={fightIcon} />
                <MenuLabel>Fight</MenuLabel>
            </MenuItem>
            <MenuItem to="/top">
                <ReactSVG src={topIcon} />
                <MenuLabel>Top</MenuLabel>
            </MenuItem>
            <MenuItem to="/rewards">
                <ReactSVG src={rewardsIcon} />
                <MenuLabel>Rewards</MenuLabel>
            </MenuItem>
        </MenuContainer>
    );
};

export default Menu;
