import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyles';
import Header from './components/Header';
import Clicker from './components/Clicker';
import UserBalance from './components/UserBalance';
import Menu from './components/Menu';
import Section from './components/Section';
import Loader from './components/Loader';
import AutoClicker from './components/AutoClicker';
import UpgradePopup from './components/UpgradePopup';
import SubscriptionCheck from './components/SubscriptionCheck';
import { getUserUpgrades, updateUserUpgrade, checkSubscription } from './api';

const App = () => {
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('');
  const [balance, setBalance] = useState(0);
  const [energy, setEnergy] = useState(0);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [energyUpgradeLevel, setEnergyUpgradeLevel] = useState(1);
  const [balanceUpgradeLevel, setBalanceUpgradeLevel] = useState(1);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);

  useEffect(() => {
    if (window.Telegram.WebApp.initData) {
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
      const user = initDataUnsafe.user;
      if (user) {
        setUsername(user.username);
        setUserId(user.id);
      }
      window.Telegram.WebApp.expand();
    }
  }, []);

  useEffect(() => {
    if (userId) {
      checkSubscription(userId)
        .then(response => {
          setIsSubscribed(response.is_subscribed);
          setIsSubscriptionChecked(true);
        })
        .catch(error => {
          console.error('Error checking subscription:', error);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      getUserUpgrades(userId)
        .then(data => {
          setEnergyUpgradeLevel(data.energyUpgradeLevel || 1);
          setBalanceUpgradeLevel(data.balanceUpgradeLevel || 1);
        })
        .catch(error => {
          console.error('Error fetching upgrades:', error);
        });
    }
  }, [userId]);

  const handleBalanceChange = useCallback((newBalance) => {
    setBalance(newBalance);
  }, []);

  const handleEnergyChange = useCallback((newEnergy) => {
    setEnergy(newEnergy);
  }, []);

  const handleUpgrade = (upgradeType, level, cost) => {
    if (upgradeType === 'energy') {
      setEnergyUpgradeLevel(level);
    } else if (upgradeType === 'balance') {
      setBalanceUpgradeLevel(level);
    }
    setBalance(prevBalance => prevBalance - cost);

    updateUserUpgrade(userId, upgradeType, level)
      .catch(error => console.error('Error updating upgrade:', error));

    setShowUpgradePopup(false);
  };

  if (!isSubscriptionChecked) {
    return <Loader />;
  }

  if (!isSubscribed) {
    return <SubscriptionCheck userId={userId} />;
  }

  return (
    <Loader>
      <Router>
        <GlobalStyle />
        <Header userId={userId} username={username} />
        <UserBalance userId={userId} balance={balance} />
        <AutoClicker userId={userId} onBalanceChange={handleBalanceChange} />
        <Routes>
          <Route
            path="/earn"
            element={
              <Clicker
                userId={userId}
                onBalanceChange={handleBalanceChange}
                onEnergyChange={handleEnergyChange}
                energyUpgradeLevel={energyUpgradeLevel}
                balanceUpgradeLevel={balanceUpgradeLevel}
              />
            }
          />
          <Route path="/invite" element={<Section title="Invite" />} />
          <Route path="/fight" element={<Section title="Fight" />} />
          <Route path="/top" element={<Section title="Top" />} />
          <Route path="/rewards" element={<Section title="Rewards" />} />
          <Route path="*" element={<Navigate to="/earn" />} />
        </Routes>
        <Menu />
      </Router>
      {showUpgradePopup && (
        <UpgradePopup
          onUpgrade={handleUpgrade}
          currentEnergyLevel={energyUpgradeLevel}
          currentBalanceLevel={balanceUpgradeLevel}
          userBalance={balance}
          onClose={() => setShowUpgradePopup(false)}
        />
      )}
    </Loader>
  );
};

export default App;
