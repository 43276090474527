import React, { memo, useMemo } from 'react';
import { EnergyBarContainer, EnergyFill, EnergyText } from '../styles/EnergyBar.styles';

const EnergyBar = memo(({ currentEnergy, maxEnergy }) => {
  const fillPercentage = useMemo(() => {
    return (currentEnergy / maxEnergy) * 100;
  }, [currentEnergy, maxEnergy]);

  return (
    <EnergyBarContainer>
      <EnergyFill style={{ width: `${fillPercentage}%` }} />
      <EnergyText>
        {currentEnergy}/{maxEnergy}
      </EnergyText>
    </EnergyBarContainer>
  );
});

export default EnergyBar;
