import React, { useEffect, useRef } from 'react';
import { BalanceContainer, Balance } from '../styles/UserBalance.styles';

const UserBalance = ({ userId, balance }) => {
  const balanceRef = useRef(balance);

  useEffect(() => {
    balanceRef.current = balance;
  }, [balance]);

  return (
    <BalanceContainer>
      <Balance>Balance: {balanceRef.current}</Balance>
    </BalanceContainer>
  );
};

export default React.memo(UserBalance);
